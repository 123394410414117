<template>
    <div class="page-container">
        <img alt="404 error" :src="require('@/assets/images/errorPage.png')">
    </div>
</template>

<script>
export default {
    name: 'errorPage',
}
</script>

<style lang="scss" scoped >

.page-container {
    padding-top: 20px;
    text-align: center;
}

img {
    max-width: 100%;
}

</style>